import { Navigate, useRoutes } from 'react-router-dom';
// layouts
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: <Navigate to="/asuper/clusters" replace />,
    },

    // Auth routes
    ...authRoutes,
    // ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    // ...mainRoutes,


    // No match 404
    // { path: '*', element: <Navigate to="/404" replace /> },
    { path: '*', element: <Navigate to="/asuper/clusters" replace /> },
  ]);
}
