import { baseUrl } from 'src/redux/slices/datagarden';
import axiosInstance from 'src/utils/axios';

class ClustersApi {
  static async editCluster(clusterId, data) {
    console.log('Edit Cluster request: ', clusterId, data);
    const res = await axiosInstance.patch(`${baseUrl}/api/clusters/${clusterId}`, data);
    return res.data;
  }

  static async deleteCluster(clusterId, params) {
    console.log('Delete Cluster request: ', clusterId, params);
    const res = await axiosInstance.delete(`${baseUrl}/api/clusters/${clusterId}`, {
      params,
    });
    return res.data;
  }
}

export default ClustersApi;
