import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import moment from 'moment';

export const baseUrl =
  localStorage.getItem('ASUPBaseUrl') ||
  process.env.REACT_APP_API_BASE_URL ||
  `${window.location.protocol}//${window.location.hostname}`;
console.log('Preset API ASUPBaseUrl', localStorage.getItem('ASUPBaseUrl'));

const initialState = {
  clusters: [],
  selectedClusterUuid: undefined,
  selectedClusterName: '',
  selectedCluster: {},
  controllers: [],
  events: [],
  disks: [],
  aggregates: [],
  volumes: [],
  licenses: [],
  systems: [],
  sumDiskTypes: {},
  sumNodeTypes: {},
  sumSpace: {},
  sumEMS: {},
  clusterIssues: [],
};

const slice = createSlice({
  name: 'datagarden',
  initialState,
  reducers: {
    setControllers(state, action) {
      const controllers = action.payload;
      state.controllers = controllers;
    },
    setEvents(state, action) {
      const { events } = action.payload;
      state.events = events;
    },
    setDisks(state, action) {
      const disks = action.payload;
      state.disks = disks;
    },
    setAggregates(state, action) {
      const aggregates = action.payload;
      state.aggregates = aggregates;
    },
    setVolumes(state, action) {
      const volumes = action.payload;
      state.volumes = volumes;
    },
    setLicenses(state, action) {
      const licenses = action.payload;
      state.licenses = licenses;
    },
    setSystems(state, action) {
      state.systems = action.payload;
    },
    setClusterStats(state, action) {
      const { sumDiskTypes, sumNodeTypes, sumSpace, sumEMS } = action.payload;
      state.sumDiskTypes = sumDiskTypes;
      state.sumNodeTypes = sumNodeTypes;
      state.sumSpace = sumSpace;
      state.sumEMS = sumEMS;
    },
    setClusterIssues(state, action) {
      state.clusterIssues = action.payload;
    },
    setClusters(state, action) {
      state.clusters = action.payload;
    },
    setSelectedClusterUuid(state, action) {
      state.selectedClusterUuid = action.payload;
    },
    setSelectedCluster(state, action) {
      state.selectedCluster = action.payload;
    },
    setSelectedClusterName(state, action) {
      state.selectedClusterName = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setControllers } = slice.actions;

export const EVENTS_SEVERITY_TYPES = [
  'DEBUG',
  'INFO',
  'ERROR',
  'NOTICE',
  'ALERT',
  'CRITICAL',
  'WARNING',
  'EMERGENCY',
];

export async function fetchEventsNoStore(
  nodeSerial,
  pageRows = 10,
  start,
  end,
  severities = EVENTS_SEVERITY_TYPES
) {
  let startParameter = '';
  let endParameter = '';
  let severitiesParameter = '';

  if (start) {
    startParameter = `&startDateTime=${moment(start).toISOString()}`;
  }
  if (end) {
    endParameter = `&endDateTime=${moment(end).toISOString()}`;
  }
  if (severities) {
    severitiesParameter = severities.map((s) => `&severity=${s}`).join('');
  }

  const response = await axios.get(
    `${baseUrl}/api/nodes/${nodeSerial}/events?reverse=false&pageRows=${pageRows}${startParameter}${endParameter}${severitiesParameter}`
  );
  return response.data;
}

export function fetchControllers() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${baseUrl}/api/nodes`);
      dispatch(slice.actions.setControllers({ controllers: response.data }));
    } catch (error) {
      console.error('controllers fetch error');
    }
  };
}

export function fetchControllerNoStore(serial) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${baseUrl}/api/nodes/${serial}`);
      return response.data;
    } catch (error) {
      return console.error('controllers fetch error');
    }
  };
}

export function fetchEvents(nodeSerial, pageRows = 10) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${baseUrl}/api/nodes/${nodeSerial}?pageRows=${pageRows}`);
      dispatch(slice.actions.setEvents(response.data));
    } catch (error) {
      console.error('events fetch error');
    }
  };
}

export async function fetchOneEventNoStore(nodeSerial, eventId) {
  const response = await axios.get(`${baseUrl}/api/nodes/${nodeSerial}/events?eventID=${eventId}`);
  return response.data;
}

export function fetchClusters(query) {
  const url = query ? `${baseUrl}/api/clusters?query=${query}` : `${baseUrl}/api/clusters`;
  return async (dispatch) => {
    dispatch(slice.actions.setSelectedClusterUuid(undefined));
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.setClusters(response.data));
    } catch (error) {
      console.error('clusters fetch error');
    }
  };
}

export async function fetchClusterNoStore(uuid) {
  const response = await axios.get(`${baseUrl}/api/clusters/${uuid}`);
  const cluster = response.data; // we take only first cluster here, but this could be changed

  return cluster;
}

export function fetchCluster(uuid) {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectedClusterUuid(uuid));
    try {
      const res = await fetchClusterNoStore(uuid);
      const {
        sumDiskTypes,
        sumNodeTypes,
        sumSpace,
        sumEMS,
        nodes,
        disks,
        aggregates,
        volumes,
        licenses,
        clusterName,
        issues,
      } = res;
      dispatch(slice.actions.setSelectedClusterName(clusterName));
      dispatch(slice.actions.setControllers(nodes));
      dispatch(slice.actions.setDisks(disks));
      dispatch(slice.actions.setAggregates(aggregates));
      dispatch(slice.actions.setVolumes(volumes));
      dispatch(slice.actions.setLicenses(licenses));
      dispatch(slice.actions.setClusterStats({ sumDiskTypes, sumNodeTypes, sumSpace, sumEMS }));
      dispatch(slice.actions.setClusterIssues(issues));
      dispatch(slice.actions.setSelectedCluster(res));
    } catch (error) {
      console.error('cluster fetch error');
    }
  };
}

export function getControllerSysconfigFileUrl(systemID, asupSequence) {
  return `${baseUrl}/api/nodes/${systemID}/files?fileID=sysconfig-a&seq=${asupSequence}`;
}

export function getAsup5link(systemID, asupFName, asupSeq) {
  return `${baseUrl}/api/nodes/${systemID}/files?fileID=${asupFName}&seq=${asupSeq}`;
}
