import { baseUrl } from 'src/redux/slices/datagarden';
import axiosInstance from 'src/utils/axios';

class CompanyApi {
  static async getCompanies() {
    const res = await axiosInstance.get(`${baseUrl}/api/companies`);
    return res.data;
  }

  /**
   * @param { number} companyId
   * @param {{"companyName": string,"comment": string}} data
   * @returns
   */
  static async editCompany(companyId, data) {
    const res = await axiosInstance.patch(`${baseUrl}/api/companies/${companyId}`, data);
    return res.data;
  }

  /**
   * @param {{ companyName: string, comment: string}} data
   * @returns
   */
  static async createCompany(data) {
    console.log(data);
    const res = await axiosInstance.post(`${baseUrl}/api/companies`, { companyId: 0, ...data });
    return res.data;
  }
}

export default CompanyApi;
