import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
import { FaChartPie } from 'react-icons/fa';
// components
import SvgColor from 'src/components/svg-color';
import {
  FaCircleNodes,
  FaDatabase,
  FaHardDrive,
  FaHashnode,
  FaKey,
  FaMicrochip,
  FaTableList,
  FaUsers,
} from 'react-icons/fa6';
import { GoOrganization } from 'react-icons/go';

import { useSelector } from '../../redux/store';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const {
    datagarden: { selectedClusterUuid, selectedCluster },
    user: { isAdmin },
  } = useSelector((state) => state);

  const adminRoutes = isAdmin
    ? [
        {
          title: 'Компании',
          path: paths.dataragden.companys,
          icon: <GoOrganization />,
        },
        {
          title: 'Пользователи',
          path: paths.dataragden.users,
          icon: <FaUsers />,
        },
      ]
    : [];

  let menu = [
    { title: 'Кластеры', path: paths.dataragden.clusters, icon: <FaCircleNodes /> },
    ...adminRoutes,
  ];

  if (selectedClusterUuid) {
    menu = menu.concat([
      {
        title: 'Статистика кластера',
        path: paths.dataragden.cluster_stats(selectedClusterUuid),
        icon: <FaChartPie />,
      },
      {
        title: 'Контроллеры',
        path: paths.dataragden.controllers_brief(selectedClusterUuid),
        icon: <FaMicrochip />,
      },
      {
        title: 'Диски',
        path: paths.dataragden.disks(selectedClusterUuid),
        icon: <FaHardDrive />,
        disabled: !selectedCluster.disks,
      },
      {
        title: 'Агрегаты',
        path: paths.dataragden.aggregates(selectedClusterUuid),
        icon: <FaDatabase />,
        disabled: !selectedCluster.aggregates,
      },
      {
        title: 'Тома',
        path: paths.dataragden.volumes(selectedClusterUuid),
        icon: <FaHashnode />,
        disabled: !selectedCluster.volumes,
      },
      { title: 'Лицензии', path: paths.dataragden.licenses(selectedClusterUuid), icon: <FaKey /> },
      {
        title: 'События',
        path: paths.dataragden.events(selectedClusterUuid),
        icon: <FaTableList />,
      },
    ]);
  }

  const data = useMemo(
    () => [
      // DATAGARDEN
      // ----------------------------------------------------------------------
      {
        subheader: 'Asuper',
        items: menu,
      },
    ],
    [menu, adminRoutes]
  );

  return data;
}
