const { createSlice } = require('@reduxjs/toolkit');

/**
 * {companyId: number, companyName: string, comment: string}[]
 */
const initialState = {
  companyList: [],
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyList(state, action) {
      state.companyList = action.payload;
    },
  },
});

export default companySlice.reducer;
export const { setCompanyList } = companySlice.actions;
