import { baseUrl } from 'src/redux/slices/datagarden';
import axiosInstance from 'src/utils/axios';

class UserApi {
  static async getUser(userId) {
    const res = await axiosInstance.get(`${baseUrl}/api/users/${userId}`);
    return res.data;
  }

  /**
   * @param {number} userId ID пользователя
   * @param {{ oldPassword, newPassword }} data Старый и ноый пароль пользователя
   * @returns
   */
  static changePass(userId, data) {
    return axiosInstance.post(`${baseUrl}/api/users/${userId}/password`, data);
  }

  static async getUsersList() {
    const res = await axiosInstance.get(`${baseUrl}/api/users`);
    return res.data;
  }

  /**
   * @param {number} userId
   * @param {{userName: string,
   * 	userRole: 'admin' | 'editor'|'user',
   *  firstName: string,
   *  lastName: string,
   * 	email: string,
   *  companyName: string,
   * 	comment: string}} data
   * @returns
   */
  static async editUser(userId, data) {
    const res = await axiosInstance.patch(`${baseUrl}/api/users/${userId}`, data);
    return res.data;
  }

  /**
   * @param {{userName: string,
   *  userRole: 'admin' | 'editor'|'user',
   *  firstName: string,
   *  lastName: string,
   * 	email: string,
   *  companyName: string,
   * 	comment: string}} data
   * @returns
   */
  static async createUser(data) {
    const res = await axiosInstance.post(`${baseUrl}/api/users`, data);
    return res.data;
  }

  static async logout() {
    const res = await axiosInstance.post(`${baseUrl}/api/auth/logout`);
    return res.data;
  }
}
export default UserApi;
