import { baseUrl } from 'src/redux/slices/datagarden';
import axiosInstance from 'src/utils/axios';

class NodesApi {
  static async updateCluster(uuid, data) {
    console.log('Edit Controller request: ', uuid, data);
    const res = await axiosInstance.patch(`${baseUrl}/api/nodes/${uuid}`, data);
    return res.data;
  }
}

export default NodesApi;
