const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  me: {
    userName: null,
    userId: null,
    userRole: null,
    firstName: null,
    lastName: null,
    email: null,
    companyName: null,
    comment: null,
  },
  isAdmin: false,
  isExtraUser: true,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.me = action.payload;
      state.isAdmin = action.payload.userRole === 'admin';
      state.isExtraUser =
        action.payload.userRole === 'admin' || action.payload.userRole === 'editor';
    },
  },
});

export default userSlice.reducer;
export const { setUserData } = userSlice.actions;
